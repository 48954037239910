.imageHandle {
  width: 10rem;
}

.aboutTitle {
  color: #874DFF;
  font-weight: bold;
}

.aboutarea {
  border-radius: 60px;
  background-color: #874DFF;
}

.an {
  color: #874DFF;
  width: 200px;
  height: 100px;
  margin: auto;
  border-radius: 2rem;
  animation-name: example;
  animation-duration: 1s;
  position: relative;
}

@keyframes example {
  from {
    top: -500px;
  }
  to {
    top: 0px;
  }
}



.italic { 
  font-style: italic;
}