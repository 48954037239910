.bgFooter {
  background-color: #874DFF;
  width: 100%;
  height: auto;
}

p:hover {
  color: #ffc500;
}

.links {
  cursor: pointer;
}

.inputCustom{
  border: 2px solid #FFC107 !important ;
  margin: 0px 5px;
}

.inputCustom :focus input{
border: #FFC107 2px solid !important;
}


textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}