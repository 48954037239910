body{
  font-family: 'El Messiri', sans-serif;}

.smallScale {
  transition: all 0.4s ease-in;
 }

.smallScale:hover {
  transform: scale(1.1);
}

.plansArea {
  width: 100%;
}

.plansArea img {
  width: 100%;
  height: 100%;
}
.titlePlans{
  background-color: #874DFF;
  padding: 1rem;
  border-radius: 1rem;
}

.btnPlans{
  background-color: #FFC107;
  padding: 1rem;

}