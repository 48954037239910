.animation {
  width: 100%;
  height: 100%;
}


.fa-location-dot:before, .fa-map-marker-alt:before {
 color:   #874DFF
}
.fa-clock-four:before, .fa-clock:before { 
  color:   #874DFF
}

.fa-envelope-open:before {
  color: #874DFF;
}

.fa-mobile-alt:before, .fa-mobile-screen-button:before{
  color: #874DFF;
}


.contact-bg {
  height: 40vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),
    url("../../image/contactus.jpg");
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-align: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-bg h3 {
  font-size: 1.3rem;
  font-weight: 400;
}
.contact-bg h2 {
  font-size: 3rem;
  padding: 0.4rem 0;
}
.line div {
  margin: 0 0.2rem;
}
.line div:nth-child(1),
.line div:nth-child(3) {
  height: 3px;
  width: 70px;
  background: white;
  border-radius: 5px;
}
.line {
  display: flex;
  align-items: center;
}
.line div:nth-child(2) {
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
}
.text {
  font-weight: 300;
  opacity: 0.9;
}
.contact-bg .text {
  margin: 1.6rem 0;
}
.contact-body {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 1rem;
}
.contact-info {
  margin: 2rem 0;
  text-align: center;
  padding: 2rem 0;
}
.contact-info span {
  display: block;
}
.contact-info div {
  margin: 0.8rem 0;
  padding: 1rem;
}
.contact-info span .fas {
  font-size: 2rem;
  padding-bottom: 0.9rem;
  color: #0a1d40;
}
.contact-info div span:nth-child(2) {
  font-weight: 500;
  font-size: 1.1rem;
}
.contact-info .text {
  padding-top: 0.4rem;
}

@media screen and (min-width: 768px) {
  .contact-bg .text {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .contact-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 992px) {
  .contact-bg .text {
    width: 50%;
  }
  .contact-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
}

@media screen and (min-width: 1200px) {
  .contact-info {
    grid-template-columns: repeat(4, 1fr);
  }
}
